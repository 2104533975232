import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { catchError, map, pluck, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { IAssetZone } from '@app/pages/settings/portfolio/interfaces/area-assets.interfaces';
import { AssetGetZonesQuery } from '@app/pages/settings/portfolio/graphql/assets-graphql.queries';

@Injectable()
export class AssetZoneNameResolver {
	constructor(private apollo: Apollo) {}

	resolve(snapshot: ActivatedRouteSnapshot) {
		return this.apollo
			.query<IAssetZone[]>({
				query: AssetGetZonesQuery,
				variables: {
					zoneId: snapshot.params.id,
				},
			})
			.pipe(
				take(1),
				pluck('data', 'zones'),
				map(zone => {
					return zone[0].name;
				}),
				catchError(err => {
					console.error(err.networkError);
					return EMPTY;
				})
			);
	}
}
